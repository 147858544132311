import React from 'react';
import { Link, graphql } from "gatsby";
import ServicesOffer from '../components/servicesOffer';
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function AboutPage({data}) {

  return (
    <Layout>
      <SEO title="About" />
      <div>
            <div className="AboutPage">
            <h2 className="d-none">About</h2>
                <div>
                    <section className="introduction">
                        <div className='container'>
                            <div>
                                <p className="title">
                                    <em>Hi there, I’m Lenny!</em> I’m a <em>UX-oriented Project Manager & Designer</em> based in Paris, France. 
I work closely with individuals and organizations to <em>build and grow long-lasting products & services</em>.
                                </p>
                                
                            </div>
                            <div>
                                <img className="About-imgPortrait" alt="Lenny Thieuleux portrait in black and white" src={data.prismicUser.data.profil_picture.url}/>
                                <div>
                                    <a target="mail" title="contact@lennythieuleux.com" href="mailto:contact@lennythieuleux.com">Email</a>
                                    <a target="linkedin" href="https://www.linkedin.com/in/lennythieuleux/">LinkedIn</a>
                                    <a target="read.cv" href="https://read.cv/lennythieuleux">Read.cv</a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="About-section1">
                        <div className='presentation'>
                            <p>
                                I graduated with a Bachelor’s Degree in Digital Project Management & Web Development at <a href="https://www.eemi.com/" className='link-anim-rev' title="École Européenne des Métiers de l'Internet">EEMI</a>, and a Master’s Degree in Design/Management of digital products and services at <a href="https://www.gobelins.fr/" className='link-anim-rev' title="Gobelins, École de l'image">Gobelins Paris</a>.
                                <br /><br />
                                I associate creative, engineering, business and management skills to get things done. I work closely with every stakeholders involved in a project, from the client to the end-user, passing by the production team.
                            </p>
                            <p>
                            Passionate about the omnichannel ecosystem, I love to research, conceive and develop innovative solutions, using my multidisciplinary skills and experiences.
                            </p>
                            <p>
                            Always eager to learn, experiment, and do better, my entrepreneurial head makes me a fearless crafter!
                            </p>
                            <p>
                                I have worked for - and with - several organizations such as local business owners, freelance collectives, creative agencies or big accounts such as leaders in cosmetic industry.
                            </p>
                            <p>
                                <i>The creative process begins here.</i>
                            </p>
                        </div>                       
                    </section>
                    <section className="About-section2">
                    <ServicesOffer/> 
                    </section>
                    <section className="About-section3">
                    <div className="careers">
                            <div>
                                <h3>Career</h3>
                            </div>
                            <div>
                                <div>
                                    <h4>NYX Professional Makeup - L’ORÉAL</h4>
                                    <ul>
                                        <li className='tags'>
                                        E-Commerce Manager DtoC - Europe Zone
                                        </li>
                                    </ul>
                                    <small>2022 - Present</small>
                                </div>
                                <div>
                                    <h4>DLinfo</h4>
                                    <ul>
                                        <li className='tags'>
                                            Digital Project Manager & Front-End Engineer
                                        </li>
                                    </ul>
                                    <small>2020 - 2022</small>
                                </div>
                                <div>
                                    <h4>Freelance</h4>
                                    <ul>
                                        <li className='tags'>
                                            Project Manager & Designer
                                        </li>
                                    </ul>
                                    <small>Since 2020</small>
                                </div>
                                <div>
                                    <h4>FOR ME LAB</h4>
                                    <ul>
                                        <li className='tags'>
                                            Front-End Engineer Intern
                                        </li>
                                    </ul>
                                    <small>Summer 2020</small>
                                </div>
                                {/* <div className="getCV">
                                <a href="https://read.cv/lennythieuleux" target="cv">Follow me on read.cv</a>
                                </div> */}
                            </div>
                        </div>
                    </section>
                    <div className='container-center'>
                        <Link className="cta" to="/work">Discover my work</Link>
                    </div>
                </div>
              </div>
        </div>
        
  </Layout>
  );
  
}

export const pageQuery = graphql`
query AboutQuery {
  prismicUser {
    data {
      company
      company_location
      position
      freelance_availability
      profil_picture{
        url
      }
    }
  }
}
`

