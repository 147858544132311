import React from 'react';

const servicesOffer = () => {
    return (
        <>
        <div className="servicesOffer">
            <div>
                <h3>Expertises</h3>
            </div>
            <div>
                <div>
                    <h4>Strategy</h4>
                    <ul>
                        <li className='tags'>
                            Market & User Research
                        </li>
                        <li className='tags'>
                            Vision & Strategy shaping
                        </li>
                        <li className='tags'>
                            UX Writing
                        </li>
                    </ul>
                </div>
                <div>
                    <h4>Interaction & Engineering</h4>
                    <ul>
                        <li className='tags'>
                            UX Design
                        </li>
                        <li className='tags'>
                            UI Design
                        </li>
                        <li className='tags'>
                            Front-End Development & Engineering
                        </li>
                    </ul>
                </div>
                <div>
                    <h4>Management</h4>
                    <ul>
                        <li className='tags'>
                            Teams & Projects coordination
                        </li>
                        <li className='tags'>
                            Project Management & Ownership
                        </li>
                        <li className='tags'>
                            Employee experience
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </>
    )
}
export default servicesOffer;
